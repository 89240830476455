const words = {
  spanish: {
    title: "Restaurante",
    description:
      "Permítase exaltar todos sus sentidos en este hermoso valle, apreciando la belleza paisajística del lugar, disfrutando de su exquisita gastronomía con su protagonista, la trucha, plato típico de la región preparada en variadas y deliciosas formas.",
    menuTitle: "Revisa nuestro ",
    menu: "menú",
    ambientTitle: "Nuestros ambientes",
    romantic: "Romántico",
    romanticText:
      "Deléitese al interior del restaurante con ambiente romántico alrededor de una calurosa chimenea.",
    nature: "Natural",
    natureText:
      "Déjese llevar por la naturaleza sentado en mesas rústicas bajo los árboles en el jardín de los gnomos disfrutando de un exquisito plato mientras apreciamos la danza de los gansos en la tranquilidad de nuestro lago.",
    cultural: "Folclór Quindiano",
    culturalText:
      "Si desea un ambiente folclórico disfrute del invernadero, con sus mesas de tronco de árbol, sintiendo la pureza del aire y el aroma de una buena taza de café de la casita quindiana.",
  },
  english: {
    title: "Restaurant",
    description:
      "Allow yourself to connect with all of your senses in this beautiful valley, admiring the amazing landscape, enjoying the exquisit cuisine with the main character, the trout, a traditional dish cooked in all of its variants.",
    menuTitle: "Check our ",
    menu: "menu",
    ambientTitle: "Our Environments",
    romantic: "Romantic",
    romanticText:
      "Give yourself a treat inside the restaurant with a romantic environment around a hot fireplace",
    nature: "Natural",
    natureText:
      "Embrace the nature while you are sitting in our rustic tables under the troll's garden trees, enjoy your meal while admiring the geese dance in the tranquility of our lake.",
    cultural: "Flokloric",
    culturalText:
      "If what you are looking for is a traditional Quindian experience, enjoy our greenhouse with its tables made out of regional trees, all while breathing the purest of airs and the scent of a good cup of coffee from our coffee house.",
  },
};

export default words;
