const words = {
  spanish: {
    title: "Quienes Somos",
    history: "Nuestra Historia",
    societies: "Asociaciones",
  },
  english: {
    title: "Who Are We?",
    history: "Our History",
    societies: "Societies",
  },
};

export default words;
