const words = {
  spanish: {
    title: "Como llegar",
  },
  english: {
    title: "How to Arrive",
  },
};

export default words;
