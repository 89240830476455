const words = {
  spanish: {
    title: "La Cabaña",
  },
  english: {
    title: "The Cabin",
  },
};

export default words;
