const words = {
  spanish: {
    title: "Zona de Camping",
    description:
      "El Eco-Hotel Las Palmas de Cocora cuenta con un amplio espacio verde bajo un cielo descubierto, en las noches poblado de estrellas y constelaciones, se ubica en el centro de un paisaje exótico y exuberante, adaptado para camping.",
    services: "En esta zona ofrecemos servicios de: ",
    parking: "Parqueadero",
    pagoda: "Ramada con Iluminación",
    bathrooms: "Baterías sanitarias",
    showers: "Duchas",
    sink: "Lava Platos",
    firewood: "Venta de leña seca, lista para ser encendida",
    campingPrice: "Valor Camping por Persona la noche: ",
    ownCampingEquipment: "llevando su equipo de camping",
    note: "NOTA: ",
    rules:
      "El camping maneja un reglamento de convivencia y comportamiento, con la finalidad de lograr mantener un ambiente tranquilo y armonioso.",
  },
  english: {
    title: "Camping",
    description:
      "The Eco-Hotel Las Palmas de Cocora has a vast green space under the night sky full with shining stars and constellations. The camping is located in the middle of an exotic scenery where you can feel connected with nature.",
    services: "Camping zone services: ",
    parking: "Parking",
    pagoda: "Illuminated Pagoda for the night",
    bathrooms: "Bathrooms",
    showers: "Shower",
    sink: "Dish washing zone",
    firewood: "We sell dry firewood ready to be lit",
    campingPrice: "Camping Price per Person per Night: ",
    ownCampingEquipment: "bringing your camping equipment",
    note: "NOTE: ",
    rules:
      "The camping zone has a regulation with the objective of maintaining order and keep a safe and calm environment.",
  },
};

export default words;
