const words = {
  spanish: {
    title: "Paseos Ecológicos a Caballo y Caminatas",
  },
  english: {
    title: "Eco-Friendly Activities",
  },
};

export default words;
