const words = {
  spanish: {
    title: "Casita del Sabor",
  },
  english: {
    title: "Coffee House",
  },
};

export default words;
